// regex для телефонов на +7, 8
export const phoneRegEx =
  /^(\+7|8)?[\s\-]?\(?[4890][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

// проверка на отсутствие пробелов, символов и цифр в первом введенном значении
export const onlyLetters = /^[а-яА-Яa-zA-Z]/;
// проверка на количество допустимых символов в поле
export const maxLength = /^.{1,50}$/;
// проверка на использование кириллицы при вводе фио
export const onlyCyrillic = /^[а-яА-ЯЁё\s'-]+$/;

export const promoEx = /^[A-Za-zА-Яа-яЁё0-9. _]{1,20}$/;

export const petNameRegEx = /^[A-Za-zА-Яа-яЁё0-9. _-]{1,40}$/;
// Может начинаться только с Буквы или Цифры. В качестве разделителей допустимо использовать символы пробела, дефис, точка, запятая, двоеточие, дробь.
export const addressRegEx =
  /^[а-яА-ЯёЁ0-9]+([ \-.,:/]?[а-яА-ЯёЁ0-9]+)*[ \-.,:/]?$/;
// Регулярное выражение взято из https://pulse-insure.atlassian.net/wiki/spaces/frontapps/pages/176980098?NO_SSR=1#Email
export const emailRegEx =
  /^((([0-9A-Za-z]{1}[-0-9A-z\.]{1,}[0-9A-Za-z]{1})|([0-9А-Яа-я]{1}[-0-9А-я\.]{1,}[0-9А-Яа-я]{1}))@([-A-Za-z]{1,}\.){1,2}[-A-Za-z]{2,})$/u;

export const onlyNumbers = /^\d+$/;

// Регулярное выражение ищет последовательности, состоящие из цифры, за которой следует пробел, и возвращает все такие совпадения в строке.
export const spaceAfterNumber = /(\d)\s/gu;
